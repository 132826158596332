
import * as storyblokRuntime$_4x8hLOin0vIhfC8isvXOh7CN05Rg6ILfTUENTwVVkSA from '/opt/build/repo/node_modules/@nuxt/image/dist/runtime/providers/storyblok'
import * as netlifyRuntime$bWghDrlYqFZ9BEAX44JpYxdndv7vT3g5oIenGXeRFBI from '/opt/build/repo/node_modules/@nuxt/image/dist/runtime/providers/netlifyImageCdn'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "netlify",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['storyblok']: { provider: storyblokRuntime$_4x8hLOin0vIhfC8isvXOh7CN05Rg6ILfTUENTwVVkSA, defaults: {"baseURL":"https://a-us.storyblok.com"} },
  ['netlify']: { provider: netlifyRuntime$bWghDrlYqFZ9BEAX44JpYxdndv7vT3g5oIenGXeRFBI, defaults: {} }
}
        